
  .bgColor{
    color: white;
    object-fit : contain;
    background-image:url('../../assets/images/home-banner.webp');
    background-repeat : no-repeat;
    background-color: rgba(0, 0, 0, 0);
    background-size: cover;
    /* background-position: center 50px; */
    width: 100%;
    height: 100vh;
  }
  @keyframes moveUpDown {
    0%, 100% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(8px);
    }
  }
  
  .fa-arrow-down {
    animation: moveUpDown 1s infinite; 
  }

  @keyframes moveRightLeft {
    0%, 100% {
      transform: translateX(0);
    }
    50% {
      transform: translateX(8px); 
    }
  }
  
  .fa-arrow-right {
    animation: moveRightLeft 1s infinite; /* Adjust the duration as needed */
  }


  .fa-arrow-right:hover{
    animation: none ;
    transition: none;
  }

  .bgColor h1{
    color:black;
  }

  .bgColor .between{
    color: #1d8533;
  }