#notfound {
    position: relative;
    height: 100vh;
}

#notfound .notfound-bg {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    overflow: hidden;
}
#notfound .notfound-bg>div:nth-child(1) {
    left: 20%;
}

#notfound .notfound-bg>div {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 1px;
    background-color: #eee;
}

#notfound .notfound-bg>div:nth-child(1):after {
    -webkit-animation: drop 3s infinite linear;
    animation: drop 3s infinite linear;
    -webkit-animation-delay: .2s;
    animation-delay: .2s;
}
#notfound .notfound-bg>div:after {
    content: '';
    position: absolute;
    top: 0;
    left: -.5px;
    -webkit-transform: translateY(-160px);
    -ms-transform: translateY(-160px);
    transform: translateY(-160px);
    height: 160px;
    width: 2px;
    background-color: #00ffc0;
}

@keyframes drop{
    90% {
        height: 20px;
    }
    100% {
        height: 160px;
        -webkit-transform: translateY(calc(100vh + 160px));
        transform: translateY(calc(100vh + 160px));
    }
}

#notfound .notfound-bg>div:nth-child(2) {
    left: 40%;
}
#notfound .notfound-bg>div {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 1px;
    background-color: #eee;
}

#notfound .notfound-bg>div:nth-child(2):after {
    -webkit-animation: drop 2s infinite linear;
    animation: drop 2s infinite linear;
    -webkit-animation-delay: .7s;
    animation-delay: .7s;
}
#notfound .notfound-bg>div:nth-child(3) {
    left: 60%;
}


#notfound .notfound-bg>div:nth-child(3):after {
    -webkit-animation: drop 3s infinite linear;
    animation: drop 3s infinite linear;
    -webkit-animation-delay: .9s;
    animation-delay: .9s;
}

#notfound .notfound-bg>div:nth-child(4) {
    left: 80%;
}

#notfound .notfound-bg>div:nth-child(4):after {
    -webkit-animation: drop 2s infinite linear;
    animation: drop 2s infinite linear;
    -webkit-animation-delay: 1.2s;
    animation-delay: 1.2s;
}

#notfound .notfound {
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%,-50%);
    -ms-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
}

.notfound {
    max-width: 520px;
    width: 100%;
    text-align: center;
}

.notfound .notfound-404 {
    height: 210px;
    line-height: 210px;
    color: #1d8533;
}

.notfound .notfound-404 h1 {
    font-family: oswald,sans-serif;
    font-size: 188px;
    font-weight: 700;
    margin: 0;
    text-shadow: 4px 4px 0 #00ffc0;
}

.notfound h2 {
    font-family: oswald,sans-serif;
    font-size: 42px;
    font-weight: 700;
    margin: 0;
    color: #1d8533;
    text-transform: uppercase;
    letter-spacing: 1.6px;
}

.notfound p {
    font-family: lato,sans-serif;
    color: #1d8533;
    font-weight: 400;
    margin-top: 20px;
    margin-bottom: 25px;
}

.notfound a {
    font-family: lato,sans-serif;
    padding: 10px 30px;
    display: inline-block;
    color: #1d8533;
    font-weight: 400;
    text-transform: uppercase;
    -webkit-box-shadow: 0 0 0 2px #1d8533, 2px 2px 0 2px #00ffc0;
    box-shadow: 0 0 0 2px #1d8533, 2px 2px 0 2px #00ffc0;
    text-decoration: none;
    -webkit-transition: .2s all;
    transition: .2s all;
}

.notfound-social {
    margin-top: 35px;
}

.notfound-social>a {
    width: 40px;
    height: 40px;
    line-height: 40px;
    padding: 0;
    margin: 0 5px;
}

.fa {
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.notfound-social>a:hover {
    background-color: #00ffc0;
    -webkit-box-shadow: 0 0 0 0 #1d8533, 0 0 0 2px #00ffc0;
    box-shadow: 0 0 0 0 #1d8533, 0 0 0 2px #00ffc0;
}

.notfound a:hover {
    
    background-color: #1d8533;
    color: white;
    -webkit-box-shadow: 0 0 0 0 #1d8533, 0 0 0 2px #00ffc0;
    box-shadow: 0 0 0 0 #1d8533, 0 0 0 2px #00ffc0;
}